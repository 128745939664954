import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";

import styled from "styled-components";
import FAQ from "../../components/FrequentlyAskedQ";
import Breadscrum from "../../components/Breadscrum";
import JumbotronV2 from "../../components/brandingV2/JumbotronV2";
import LinkButtonV2 from "../../components/brandingV2/LinkButtonV2";
import Gdpr from "../../assets/img/gdpr.svg";
import Proces1 from "../../assets/img/archiveProces1.svg";
import Proces2 from "../../assets/img/selectUser.svg";
import Proces3 from "../../assets/img/upload.svg";
import Proces4 from "../../assets/img/willProces4.svg";
import CareIcon from "../../assets/img/care.svg";
import Prepaid from "../../assets/img/prepaid.svg";
import Will from "../../assets/img/will.svg";
import Privacy from "../../assets/img/privacy.svg";
import willFeature1 from "../../assets/img/willFeature1.svg";
import willFeature2 from "../../assets/img/willFeature2.svg";
import willFeature3 from "../../assets/img/willFeature3.svg";
import willFeature4 from "../../assets/img/willFeature4.svg";
import willFeature5 from "../../assets/img/willFeature5.svg";
import willFeature6 from "../../assets/img/willFeature6.svg";
import willFinalCta from "../../assets/img/willFinalCta.svg";
// import Proces4 from "../../assets/img/willProces4.svg";
import Czechpoint from "../../assets/img/czechpoint.svg";
import RecenzeV2 from "../../components/brandingV2/RecenzeV2";
import Proces from "../../components/brandingV2/Proces";
import BlogPreview from "../../components/brandingV2/BlogPreview";
import { useMediaQuery } from "react-responsive";
import CallButtonV2 from "../../components/brandingV2/CallButtonV2";
import NonprofitSmall from "../../components/brandingV2/NonprofitSmall";
import Check from "../../assets/img/willCheck.svg";
import IsoBadge from "../../assets/img/isoBadge.svg";
import Soc2 from "../../assets/img/soc2.svg";
import FinalCta from "../../components/brandingV2/FinalCta";
import ArchiveInfo from "../../components/zavetiV2/archiveInfo";
import FeaturesV2 from "../../components/brandingV2/FeaturesV2";
import deathVerification from "../../assets/img/deathVerification.svg";


// make it shorter 





const faqData = [
  {
    otazka: "Jak se dozvíte, že jsem zemřel?",
    odpoved: "Proces ověření je důkladnější než jen jednochuché zaslání úmrtího listu. Nejprve někdo z vašich blízkých nahlásí vaše úmrtí. Následně se s vámi pokusíme spojit, dohledáme úmrtní list či list o prohlídce zemřelého, kontrolujeme aktivitu na vašem účtu, poslední přihlášení, pokoušíme se spojit s ostatními rodinými příslušníky a notáři. Jakmile všechny strany dojdou ke shodě - vaše dokumenty uvolníme. Celý proces a trvá v průměru 30 dnů.",
  },
  {
    otazka: "Proč nepoužívat Dropbox, Google Drive nebo iCloud?",
    odpoved: "Cloud služby, jako je např. Dropbox, Google Drive nebo iCloud jsou sice perfektní pro každodenní sdílení souborů, ale na komplexní přípravu a uschování citlivých dokumentů nemusí stačit. Naše služba je mnohem více zabezpečená a současně vám dává více možností, jak rozdělit, komu jaký soubor připadne. Do platformy můžete pozvat všechny své blízké, případně i účetního, právního poradce, který vám bude moci pomoci s přípravou. Současně za vás řešíme i ověření vašeho úmrtí a uvolnění dokumentů (u ostatních služeb to může trvat až roky).",
  },
  {
    otazka: "Můžu sdílet část svého archivu se svým účetním/právníkem?",
    odpoved: "Ano, můžete. Pro jednotlivé soubory je možné nastavit kokrétní příjemce, kterému soubor připadne při uvolnění.",
  },
  {
    otazka: "Potřebujete nutně doložit úmrtní list?",
    odpoved: "Ověření úmrtí je vždy jednodušší, když je úmrtní list k dispozici. Náš proces však obsahuje i spoustu jiných kroků, skrz které lze úmrtí ověřit."
  },
  {
    otazka: "Je možné skrýt některé dokumenty před ostatními lidmi?",
    odpoved: "Ano, možné to je. Vždy si vyberete komu jaký soubor připadne. Daného člověka tedy stačí jen nezahrnout do požadované složky.",
  },
];

const recenze = [
  {
    recenze: "Vše proběhlo rychle, srozumitelný formulář, příjemné jednání.",
    jmeno: "Zita J.",
    mesto: "Praha",
  },
  {
    recenze: "Děkuji za pomoc. Celý proces byl rychlý, profesionální - doporučuji dál!",
    jmeno: "Jitka",
    mesto: "Brno",
  },
  {
    recenze: "Velice jednoduchý nástroj na tvorbu závětí. Cena v pořádku.",
    jmeno: "Vladimír M.",
    mesto: "Praha",
  },
  {
    recenze: "Líbí se mi, že si závěť můžu udělat v klidu doma a nemusím kvůli tomu nikam jezdit.",
    jmeno: "Alena",
    mesto: "Ústí nad Labem",
  },
  {
    recenze: "Vše bylo velmi dobře vysvětlené a snadné... Za večer jsem měla hotovo.",
    jmeno: "Kateřina",
    mesto: "Liberec",
  },
  {
    recenze: "Se společností a rychlostí vyhotovení závěti jsem nadmíru spokojena.",
    jmeno: "Lucie",
    mesto: "Praha",
  },
  {
    recenze: "Opravdu perfektní služba, jen tak dál!",
    jmeno: "Jiří",
    mesto: "Plzeň",
  },
  {
    recenze: "Jednoduche vysvetleni, rychle vyplneni a zpetna vazba. Doporucuji vsem si zavet touto cestou udelat a zajistit klid sobe i pripadnym dedicum.",
    jmeno: "Petr",
    mesto: "Brno",
  },
  {
    recenze: "Moc mi vaše služba pomohla vyřešit narychlo závěť před dovolenou.",
    jmeno: "Anna",
    mesto: "Kladno",
  },
];

let structuredData = {
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "Digitální archiv",
  "brand":"Goodbye.cz",
  "image":"https://goodbye.cz/zavet.jpg",
  "description": "Bezpečně uchováme vaše dokumenty, informace a hesla, které po vaší smrti doručíme lidem, které jste si zvolili.",
  "aggregateRating": {
    "@type":"AggregateRating",
    "bestRating": "5",
    "ratingCount": 147,
    "ratingValue": 4.93,
    "worstRating": "1"}
};

const DigiArchivInner = styled.div`
  .nowrap {
    white-space: nowrap;
    font-family: inherit;
  }

  .leftJumbo {
    max-width: 700px !important;

    h1 {
      line-height: 60px;

      @media (max-width: 960px) {
        font-size: 39px !important;
        line-height: 50px;
      }

      @media (max-width: 550px) {
        font-size: 33px !important;
        line-height: 44px;
        margin-bottom: 20px !important;
      }

      @media (max-width: 460px) {
        font-size: 27px !important;
        line-height: 34px;
      }

      @media (max-width: 430px) {
        margin-top: 10px !important;
      }
  


    }

    .desc {
      font-size: 20px !important;
      line-height: 30px !important;

      @media (max-width: 430px) {
        font-size: 16px !important;
        line-height: 24px !important;
        margin-bottom: 30px !important;
      }
    }

    ul {
      margin-top: 35px;
      margin-bottom: 40px;

      @media (max-width: 430px) {
        margin-top: 25px;
        margin-bottom: 30px;
      }

      li {
        list-style: none;
        display: flex;
        margin-bottom: 15px;
        align-items: center;

        @media (max-width: 430px) {
          margin-bottom: 20px;
        }

        img {
          margin-right: 10px;

          @media (max-width: 430px) {
            margin-right: 13px;
          }
        }

        span {
          font-family: Visuelt-Regular;
          font-size: 18px;
          color: #394B5C;
          line-height: 28px;

          a {
            color: #394B5C;
            text-decoration: underline;
          }

          @media (max-width: 430px) {
            font-size: 16px;
            line-height: 23px;
          }
        }
      }
    }

    .linkButton {
      @media (max-width: 660px) {
        margin-right: 20px !important;
      }

      @media (max-width: 360px) {
        margin-right: 0 !important;
      }
    }

    img {
      &:nth-child(2) {
        margin-right: 20px;
      }
    }

    .buttons {
      margin-bottom: 0 !important;

      img {
        vertical-align: middle;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;

          @media (max-width: 350px) {
            margin-right: 15px;
            margin-top: 15px;
          }
        }
      }

      div {
        img {
          margin-right: 0;
          
          @media (max-width: 350px) {
            margin-right: 0 !important;
            margin-top: 0 !important;
          }
        }
      }
    }

  }

  .rightJumbo {
    .obrazek {
      margin: -50px auto;

      @media (max-width: 1130px) {
        height: 330px !important;
        width: 300px !important;
      }

      @media (max-width: 1020px) {
        height: 300px !important;
        width: 250px !important;
        margin: 0;
        margin-bottom: 30px;
      }

      @media (max-width: 450px) {
        height: 240px !important;
        width: 220px !important;
        margin-bottom: 20px;
        margin-top: -10px;
      }
    }
  }

  .simpleSec {
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 70px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 899px) {
      max-width: 700px;
      width: calc(100% - 50px);
    }

    @media (max-width: 600px) {
      margin: 50px auto;
    }

    @media (max-width: 630px) {
      display: block;
    }

    h2 {
      font-family: NewKansas;
      font-size: 36px;
      color: #2c4668;

      @media (max-width: 899px) {
        font-size: 32px;
        line-height: 40px;
      }

      @media (max-width: 430px) {
        font-size: 28px;
        line-height: 35px;
      }
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 18px;
      color: #394B5C;
      line-height: 28px;
      margin-top: 30px;

      a {
        color: #394B5C;
      }

      @media (max-width: 430px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .left {
      width: calc(100% - 250px - 70px);
      max-width: 700px;

      @media (max-width: 950px) {
        width: calc(100% - 200px - 50px);
      }

      @media (max-width: 630px) {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }

  .vCemJsmeJini {
    max-width: 1280px;
    margin: 0 auto;
    background: #F4F3F0;
    border-radius: 20px;
    padding: 60px 0;
    width: calc(100% - 50px);
    margin-bottom: 70px;

    @media (max-width: 700px) {
      padding: 40px 0;
    }

    @media (max-width: 600px) {
      margin: 50px auto;
    }

    @media (max-width: 430px) {
      width: calc(100% - 20px);
    }

    h2 {
      width: calc(100% - 50px);
      max-width: 1150px;
      margin: 0 auto;
    }

    .desc {
      width: calc(100% - 50px);
    }

    .features {
      width: calc(100% - 50px);

      @media (max-width: 900px) {
        /* width: calc(100%); */
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .privacy {
    padding-bottom: 70px;
    border-bottom: 1px solid #B2B2B2;

    @media (max-width: 630px) {
      padding-bottom: 50px;
      margin-bottom: -20px;
    }

    .privacyPic {
      width: 300px;
      
      @media (max-width: 950px) {
        width: 200px;
      }
    }
  }

  .experts {
    p {
      margin-bottom: 25px;
    }

    .deathVerification {
      width: 350px;

      @media (max-width: 950px) {
        width: 200px !important;
        height: 118px !important;
      }
    }
  }
`;

const DigitalniArchiv = ({ data }) => {
  const isLess500 = useMediaQuery({
    query: '(max-width: 500px)'
  });

  return (
    <Layout
      customCtaLink={"https://forms.gle/mA2FQZbTcumKpAMF8"}
      customLoginLink={"/digitalni-archiv/archiv/"}
      customCtaText={"Založit účet"}
      subMenuItems={[
        {
          name: "Závěti",
          url: "/zaveti/",
        },
        {
          name: "Digitální archiv",
          url: "/digitalni-archiv/",
        },
        {
          name: "Předplacený pohřeb",
          url: "/predplaceny-pohreb/",
        },
      ]}
    >
      <SEO
        title="Digitální archiv: Vaše online bezpečnostní schránka | Goodbye.cz"
        customTitle
        description="Uchováme vaše dokumenty, informace a hesla, které po vaší smrti doručíme lidem, které si zvolíte. Vojenská úroveň zabezpečení dat, podpora CzechPoint."
        image="/archivOg.png"
      />

      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}}/>

      <DigiArchivInner>
        <JumbotronV2
          noSplatky
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          h1={"Digitální archiv: Vaše online bezpečnostní schránka"}
          hideSecondHeading
          noReview
          customPerex={<>
            <p className="desc">Bezpečně uchováme vaše dokumenty, informace a hesla, které po vaší smrti doručíme lidem, které jste si zvolili.</p>
            <ul>
              <li>
                <img src={Check} alt="" />
                <span>Zabezpečení dat na vojenské úrovni (přečtěte si náš <a href="https://app.vanta.com/thepreparedcompany/trust/qolgf777609mq2umkm8h2" target="_blank">bezpečnostní audit</a>)</span>
              </li>
              <li>
                <img src={Check} alt="" />
                <span>Jednoduché řešení ke kterému máte vždy, 24/7 přístup</span>
              </li>
              <li>
                <img src={Check} alt="" />
                <span>Podporujeme digitální konverzi dokumentů na <a href="https://www.czechpoint.cz/public/verejnost/autorizovana-konverze/" target="_blank">CzechPoint</a></span>
              </li>
            </ul>
          </>}
          reviewDesc={<>Stovky spokojených zákazníků <span className="nowrap">(4,95/5 průměrné hodnocení)</span></>}
          breadscrum={
            <Breadscrum
              visible={true}
              stranky={[
                {name: "Domů", url: "/"},
                {name: "Digitální archiv", url: "/digitalni-archiv/"}
              ]}
            />
          }
          customCta={[
            <LinkButtonV2 to="https://forms.gle/mA2FQZbTcumKpAMF8" arrow text="Požádat o založení účtu" />,
            <img src={Soc2} alt="SOC 2 type 2 certification" />,
            <img src={IsoBadge} alt="ISO 27001" />,
            // <img src={Gdpr} alt="V souladu s GDPR" />,
            <img src={Czechpoint} alt="CzechPoint logo" />,
          ]}
        />


        <RecenzeV2 
          customReviews={recenze}
          sklonovaneMesto={""}
          customH2={"Váš důvěryhodný partner při plánování pozůstalosti"}
        />

        <ArchiveInfo />

        <Proces 
          h2="Založte si účet a začněte ještě dnes"
          procesDesc={"Jeden večer práce může znamenat až měsíce ušetřených starostí pro vaše blízké."}
          cutomTabs={[
            {
              num: 1,
              pic: Proces1,
              heading: "Vytvořte si svůj archiv za pár minut",
              desc: "Přístupy do digitálního archivu vám zašleme obratem po poptávce, v rámci sepsání závěti a nebo předplaceného pohřbu.",
            },
            {
              num: 2,
              pic: Proces2,
              heading: "Vyberte osoby, kterým po vaší smrti zašleme dokumenty",
              desc: "Ať už se jedná o kolegy z práce nebo vaší rodinu, pošlete jim pozvánku a nastavte jejich pravomoce.",
            },
            {
              num: 3,
              pic: Proces3,
              heading: "Nahrajte důležité dokumenty a vytvořte instrukce",
              desc: "Nahrajte všechny důležité soubory, které po vaší smrti mohou vaši blízcí potřebovat a vytvořte k nim instrukce.",
            },
            {
              num: 4,
              pic: Proces4,
              heading: "Ověříme úmrtí a všechny dokumenty doručíme",
              desc: "Jakmile důkladně prověříme, že jste opravdu zemřeli, zašleme všechny dokumenty vybraným příjemcům.",
            },
          ]}
        />

        <div className="simpleSec privacy">
          <div className="left">
            <h2>Bezpečnost vašich dat bereme opravdu vážně</h2>
            <p>Víme, že svěřit někomu své důvěrné dokumenty a hesla není jen tak. Používáme proto to nejpokročilejší šifrování dat na vojenské úrovni, podobně jako vaše banka. Vaše data a soubory jsou s námi v mnohem větším bezpečí, než kdybyste využili ostatní cloud služby jako např. Dropbox, či Google Drive.<br/><br/>Nikdo z našich zaměstnanců nemá přístup k vašim soukromým souborům. Naše aplikace splňuje ty nejpřísnější standarty ISO2700, SOC-II a GDPR. Všechny soubory se šifrují podle unikátního klíče každého uživatele 2x ještě před tím, než vůbec dorazí do naší databáze a potom ještě jednou - pro jistotu. Samotnou databázi potom zálohujeme 3x denně. Podporujeme vícefaktorové ověření (MFA), Unique Device Fingerprinting, TLS Encryption a mnoho dalších bezpečnostních prvků.<br/><br/>Nezávislá bezpečnostní společnost <a target="_blank" href="https://app.vanta.com/thepreparedcompany/trust/qolgf777609mq2umkm8h2">Vanta</a> 24/7 monitoruje a audituje naší aplikaci a servery.</p>
          </div>
          <img className="privacyPic" src={Privacy} alt="Vaše soukromí na prvním místě" />
        </div>

        <div className="simpleSec experts">
          <div className="left">
            <h2>Jak se dozvíme, že jste zemřeli?</h2>
            <p>Ještě předtím, než se vaše dokumenty odešlou lidem, které jste zvolili, musíme ověřit, že jste opravdu zemřeli a zamezit tak možnému podvodu. Proces ověření je důkladnější než jen jednochuché zaslání úmrtího listu a trvá v průměru 30 dnů.<br/><br/>Jako první někdo z vašich blízkých smrt nahlásí. Dále se s vámi budeme snažit spojit, dohledáme list o prohlídce zemřelého či úmrtní list, kontrolujeme poslední přihlášení, aktivitu na vašem účtu, pokoušíme se spojit s ostatními rodinými příslušníky a notáři. Jakmile všechny strany dojdou ke shodě - vaše dokumenty uvolníme.</p>
          </div>
          <img src={deathVerification} alt="Ověření úmrtí" className="deathVerification" />
        </div>
        
        
        <FeaturesV2
          customH2="Komplexní příprava na moment, kdy odejdete"
          desc="Připravte se dopředu a odděchněte si. V Goodbye.cz vám pomůžeme vše zorganizovat a zařídit se na to, co přijde."
          text1="Sepište si u nás závěť a postarejte se o své nejbližší. Všechny důležité dokumenty potom nahrajte do digitálního archivu. Hotovo máte do 20 minut za pouhých 2 190 Kč."
          text2="Předplaťte si pohřeb jednoduše online nebo přes telefon. Plánujte dopředu a ušetřete svým nejbližším starosti a stres z plánování pohřbu."
          text3="Aplikace a 24/7 asistenční služba, která pomůže vaší rodině se zorientovat a vyřešit všechny úkoly související se vaším úmrtím."
          heading1="Online závěť a digitální archiv"
          heading2="Předplacený pohřeb na splátky"
          heading3="Administrativní i psychická podpora pro truchlící rodiny Goodbye Care"
          ilustrace1={Will}
          ilustrace2={Prepaid}
          ilustrace3={CareIcon}
          callButton
        />

        <FAQ data={faqData} />

        <FinalCta
          image={willFinalCta}
          imageAlt="Poslední vůle"
          h2="Začněte ještě dnes"
          p="Připravte se a ušetřete svým blízkým starosti po vašem úmrtí."
          ctaLink="https://forms.gle/mA2FQZbTcumKpAMF8"
          ctaText="Založit digitální archiv"
        />

        <BlogPreview wills />


      </DigiArchivInner>
    </Layout>
  );
}

export const query = graphql`
  query {
    jumboObrazek: file(relativePath: { eq: "digiArchive.png" }) {
      childImageSharp {
        fixed(width: 395) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jumboObrazekMobile: file(relativePath: { eq: "digiArchive.png" }) {
      childImageSharp {
        fixed(width: 290) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    willsMac: file(relativePath: { eq: "willsMac.png" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    bigTeam: file(relativePath: { eq: "podpora.png" }) {
      childImageSharp {
        fixed(width: 250, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    smallTeam: file(relativePath: { eq: "podpora.png" }) {
      childImageSharp {
        fixed(width: 160, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

export default DigitalniArchiv;


